<template>
    <u-chip-flat-pill v-bind="$attrs" text-color="white" @click="$emit('click')" x-small>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-chip-flat-pill>
</template>

<script>
import UChipFlatPill from "@/components/publish/styles/chips/u-chip-flat-pill.vue";

export default {
    props: {},
    components: {
        UChipFlatPill,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
