var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-data-table--line",
    attrs: {
      "hide-default-header": "",
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, scopedSlotName) {
      return {
        key: scopedSlotName,
        fn: function (slotData) {
          return [_vm._t(scopedSlotName, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'v-data-table', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }