<template>
    <u-chip-flat-tile v-bind="$attrs" text-color="white" @click="$emit('click')" class="v-size--large">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-chip-flat-tile>
</template>

<script>
import UChipFlatTile from "@/components/publish/styles/chips/u-chip-flat-tile.vue";

export default {
    props: {},
    components: {
        UChipFlatTile,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-chip {
    justify-content: center;
}
</style>
