<template>
    <u-sub-head-basic v-bind="$attrs" tabsTag="u-tabs-dot" tabTag="u-tab-dot" :tabsAttrs="{ class: 'v-tabs--wide-border' }" class="sub-head--with-header">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-sub-head-basic>
</template>

<script>
import USubHeadBasic from "@/sets/styles/pages/u-sub-head-basic.vue";

export default {
    props: {},
    components: {
        USubHeadBasic,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    .sub-tab-wrap {
        display: none;
    }
}
</style>