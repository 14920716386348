<template>
    <u-chip-flat-pill v-bind="$attrs" @click="$emit('click')" large>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-chip-flat-pill>
</template>

<script>
import UChipFlatPill from "@/components/publish/styles/chips/u-chip-flat-pill.vue";

export default {
    props: {},
    components: {
        UChipFlatPill,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-chip {
    min-width: none;
    // width: 100%;
    // justify-content: center;
}
@media (min-width: 1024px) {
    .v-chip {
        width: 100%;
    }
}
</style>
