var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('th', _vm._b({
    staticClass: "text-center grey lighten-4 grey--text"
  }, 'th', _vm.$attrs, false), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }